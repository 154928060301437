/**
 *  Layouts / Index
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The main layout for our component
 *
 */

/** Dependencies */
import React from "react";

/** Local Dependencies */
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";

/** Stylesheets */
import * as styles from "./index.module.scss";

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <>
    <Header />
    <div className={styles.content}>{children}</div>
    <Footer />
  </>
);

export default Layout;
