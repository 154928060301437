/**
 *  Components / Header
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Header
 *
 */

/** Dependencies */
import React, { useState, useCallback } from "react";
import { Link } from "gatsby";
import classNames from "classnames";

/** Local Dependencies */
import { Logo } from "../Logo";
import { CTA } from "../CTA";

/** Styles */
import * as styles from "./Header.module.scss";

/** Component */
export const Header: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleMenu = useCallback(() => setOpen((value) => !value), []);

  return (
    <header className={styles.root}>
      <div className={styles.smallLogo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <button className={styles.button} onClick={handleMenu}>
        <div
          className={classNames(styles.buttonIcon, open && styles.buttonOpen)}
        />
      </button>
      <div className={classNames(styles.content, open && styles.contentOpen)}>
        <div className={styles.largeLogo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={styles.actions}>
          <nav className={styles.navigation}>
            <Link
              to="/"
              className={styles.link}
              activeClassName={styles.linkActive}
            >
              Бетонов Център
            </Link>
            <Link
              to="/certificates"
              className={styles.link}
              activeClassName={styles.linkActive}
            >
              Сертификати
            </Link>
            <Link
              to="/about"
              className={styles.link}
              activeClassName={styles.linkActive}
            >
              За нас
            </Link>
            <Link
              to="/contact"
              className={styles.link}
              activeClassName={styles.linkActive}
            >
              Контакти
            </Link>
          </nav>
          <CTA href="tel:0887277070">Поръчай сега!</CTA>
        </div>
      </div>
    </header>
  );
};
