/**
 *  Components / Footer
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Footer
 *
 */

/** Dependencies */
import React from "react";

/** Styles */
import * as styles from "./Footer.module.scss";

/** Component */
export const Footer: React.FC = () => (
  <footer className={styles.root}>
    <div className={styles.text}>
      © 2021 Всички права запазени. Примабилд ООД.
    </div>
    <div className={styles.text}>
      Производство и доставка на бетон и бетонови смеси.
    </div>
  </footer>
);
