/**
 *  Components / CTA
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  CTA
 *
 */

/** Dependencies */
import React from "react";
import classNames from "classnames";

/** Styles */
import * as styles from "./CTA.module.scss";

/** Props */
interface CTAProps extends React.ComponentProps<"a"> {}

/** Component */
export const CTA: React.FC<CTAProps> = ({ className, ...props }) => (
  <a {...props} className={classNames(styles.root, className)} />
);
