// extracted by mini-css-extract-plugin
export var actions = "Header-module--actions---ifnF";
export var button = "Header-module--button--Ikb06";
export var buttonIcon = "Header-module--buttonIcon--JL+xN";
export var buttonOpen = "Header-module--buttonOpen--VeDZY";
export var content = "Header-module--content---SXGa";
export var contentOpen = "Header-module--contentOpen--eMW6p";
export var largeLogo = "Header-module--largeLogo--HViot";
export var link = "Header-module--link--VyWp4";
export var linkActive = "Header-module--link-active--NC4Es";
export var navigation = "Header-module--navigation--rLyZT";
export var root = "Header-module--root--bRQpA";
export var smallLogo = "Header-module--smallLogo--dKgwX";